@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic|PT+Sans&display=swap);
body {
  font-family: Avenir Next,'Nanum Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}
a{
  color: #C8AEFF;
  text-decoration: none;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


